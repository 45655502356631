<template>
  <v-container>
    <h1>Flux ITxxxxx</h1>
    
    <!-- LONATO -->
    <div class="cadre">
      <h-group>
        <div class="etat ok"></div>
        <div class="mr-2 titres">
          <h2>LONATO</h2>
          <div>ETD LONATO 22-04-2022</div>
          <div>ETA MERREY 24-04-2022</div>
        </div>
        <v-group>
          <v-btn color="success" class="mb-2">voir les wagons</v-btn>
          <v-btn color="success">voir les P.O.</v-btn>
        </v-group>
      </h-group>
    </div>

    <!-- MERCITALIA -->
    <div class="cadre">
      <h-group>
        <div class="etat ok"></div>
        <div class="mr-2 titres">
          <h2>TRAIN MERCITALIA</h2>
        </div>
        <div class="mr-2">
          <img src="@/assets/mercitalia-logo.png" alt="" class="logo"/>
        </div>
        <v-group>
          <v-btn color="success" class="mb-2">voir les wagons</v-btn>
        </v-group>
      </h-group>
    </div>

    <!-- AMBERIEU -->
    <div class="cadre">
      <h-group>
        <div class="etat ok"></div>
        <div class="mr-2 titres">
          <h2>AMBERIEU</h2>
        </div>
      </h-group>
    </div>

    <!-- EURORAIL -->
    <div class="cadre">
      <h-group>
        <div class="etat alerte"></div>
        <div class="mr-2 titres">
          <h2>TRAIN EURORAIL</h2>
        </div>
        <div class="mr-2">
          <img src="@/assets/eurorail-logo.png" alt="" class="logo"/>
        </div>
        <v-group>
          <v-btn color="success" class="mb-2">voir les wagons</v-btn>
        </v-group>
      </h-group>
    </div>

    <!-- CULMONT CHALENDREY -->
    <div class="cadre">
      <h-group>
        <div class="etat arret"></div>
        <div class="mr-2 titres">
          <h2>CULMONT CHALENDREY</h2>
        </div>
      </h-group>
    </div>

    <!-- CAPTRAIN -->
    <div class="cadre">
      <h-group>
        <div class="etat arret"></div>
        <div class="mr-2 titres">
          <h2>TRAIN EURORAIL</h2>
        </div>
        <div class="mr-2">
          <img src="@/assets/captrain-logo.png" alt="" class="logo"/>
        </div>
        <v-group>
        </v-group>
      </h-group>
    </div>


    <!-- MERREY -->
    <div class="cadre">
      <h-group>
        <div class="etat arret"></div>
        <div class="mr-2 titres">
          <h2>MERREY</h2>
        </div>
      </h-group>
    </div>

  </v-container>

</template>

<script>
export default {

}
</script>

<style scoped>

.etat {
  width: 200px;
  height: 100px;
  margin-right: 10px;
}
.arret {
  background-color: lightgray;
}

.alerte {
  background-color: orange;
}

.ok {
  background-color: #00FF00;
}

.erreur {
  background-color: red;
}

.cadre {
  border-bottom: 1px solid black;
}

.logo {
  max-height: 60px;
}

.titres {
  width: 500px
}

</style>